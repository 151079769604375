import Swiper, { Navigation, Pagination } from 'swiper';

// Slider
function sliders(el, config) {
	if (!el) {
		return false;
	}
	const slider     = el.querySelector('.swiper');
	const next       = el.querySelector('.swiper-button-next');
	const prev       = el.querySelector('.swiper-button-prev');
	const pagination = el.querySelector('.swiper-pagination');
	const settings   = {
		modules               : [Navigation, Pagination],
		loop                  : false,
		slidesPerView         : 'auto',
		spaceBetween          : 0,
		preloadImages         : false,
		watchSlidesVisibility : true,
		watchOverflow         : true,
		threshold             : 10,
		observer              : true,
		navigation            : {
			nextEl : next,
			prevEl : prev,
		},
		pagination : {
			el             : pagination,
			type           : 'bullets',
			clickable      : true,
			dynamicBullets : false,
		},
	};

	if (config) {
		Object.assign(settings, config);
	}

	return new Swiper(slider, settings);
}

(function ($) {
	$(() => {
		const newsRelative = $('[data-slider="news-relative"]');
		newsRelative.each((_, element) => {
			sliders(element);
		});

		const deliveryMethods = $('[data-slider="delivery-methods"]');
		deliveryMethods.each((_, element) => {
			sliders(element);
		});

		const aboutGallery = $('[data-slider="about-gallery"]');
		aboutGallery.each((_, element) => {
			sliders(element);
		});

		const recommendSlider = $('[data-slider="recommend"]');
		recommendSlider.each((_, element) => {
			sliders(element);
		});
	});
	// eslint-disable-next-line no-undef
}(jQuery));
